import React, { useEffect, useState } from 'react';
// IndexedDb
import { useLiveQuery } from 'dexie-react-hooks';
import { dexieDB } from '../../shared/db';
//Models
import { ProductInterface } from '../../shared/types';
//Api
import { getVendorProducts } from 'Api';
//Styles
import { Col, Row, Button, Spinner } from 'react-bootstrap';
//Components
import { SimpleProducts, VariableProducts } from 'components/products';

export const SelectProductsPage: React.FC = () => {
  const [products, setProducts] = useState<ProductInterface[]>([]);
  const [sortField, setSortField] = useState<keyof ProductInterface | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [searchFilter, setSearchFilter] = useState('');
  const [currentPage] = useState<number>(1);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [hoverField, setHoverField] = useState<string | null>('');
  const [isAdding, setIsAdding] = useState(false);
  const [visibleCount, setVisibleCount] = useState<number>(20);

  const selectedVendors = useLiveQuery(() => dexieDB.vendors.toArray());

  const selectedCategories = useLiveQuery(() => dexieDB.categories.toArray());

  const selectedProducts = useLiveQuery(() => dexieDB.selectedProducts.toArray());

  const handleSort = (field: string) => {
    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field as keyof ProductInterface);
      setSortOrder('asc');
    }

    localStorage.setItem('selectSortField', field);
    localStorage.setItem('selectSortOrder', sortOrder === 'asc' ? 'desc' : 'asc');
  };

  useEffect(() => {
    if (!selectedVendors || selectedVendors.length === 0 || !selectedCategories || selectedCategories.length === 0) {
      return;
    }

    const getVendorProductsHandler = async (): Promise<void> => {
      const vendorIds: number[] = [];
      const categories: string[] = [];

      if (selectedVendors) {
        for await (const vendor of selectedVendors) {
          if (vendor.vendors_id) {
            vendorIds.push(vendor.vendors_id);
          }
        }
      }

      if (selectedCategories) {
        for await (const category of selectedCategories) {
          if (category.vendor_products_category) {
            categories.push(category.vendor_products_category);
          }
        }
      }

      if (searchFilter.length === 0 || searchFilter.length >= 3) {
        const response = await getVendorProducts(searchFilter.trim(), vendorIds, categories, currentPage, totalResults, sortOrder, sortField?.toString());

        if (response) {
          const fetchedProducts = [...response.data.results];

          setProducts(fetchedProducts);
          setTotalResults(response.data.total);
        }
      }
    };
    getVendorProductsHandler();
  }, [selectedVendors, selectedCategories, searchFilter, currentPage, totalResults, sortField, sortOrder]);

  useEffect(() => {
    const savedSortField = localStorage.getItem('selectSortField');
    const savedSortOrder = localStorage.getItem('selectSortOrder');

    if (savedSortField) {
      setSortField(savedSortField as keyof ProductInterface);
    }

    if (savedSortOrder) {
      setSortOrder(savedSortOrder as 'asc' | 'desc');
    }
  }, []);

  const handleSelectAll = async (): Promise<void> => {
    if (isAdding) return;
    setIsAdding(true);

    const bulkCreate: ProductInterface[] = [];

    for await (const product of products) {
      const updatedProduct = { ...product, vendor_products_status: 'draft' };

      if (product.vendor_products_id && selectedProducts?.some((selectedProduct) => selectedProduct.vendor_products_id === product.vendor_products_id)) {
        await dexieDB.selectedProducts.delete(product.vendor_products_id);
        await dexieDB.editedProducts.delete(product.vendor_products_id);
      }

      bulkCreate.push(updatedProduct);
    }

    try {
      await dexieDB.selectedProducts.bulkPut(bulkCreate);
      await dexieDB.editedProducts.bulkPut(bulkCreate);
    } catch (error) {
      console.error('Error updating database:', error);
    }

    setIsAdding(false);
  };

  const handleDeselectAll = async (): Promise<void> => {
    await dexieDB.selectedProducts.clear();
    await dexieDB.editedProducts.clear();
  };

  const handleShowMore = () => {
    setVisibleCount((count) => count + 10);
  };

  const handleShowAll = () => {
    setVisibleCount(totalResults);
  };

  function calculateTotalMarked() {
    let total = 0;

    if (selectedProducts) {
      selectedProducts.forEach((product) => {
        total += 1;

        if (product.product_variations) {
          total += product.product_variations.length;
        }
      });
    }

    return total;
  }

  return (
    <>
      <Row className="heading-intro">
        <Col>
          <h1>Produkter</h1>
          <h5>Vælg de produkter du vil importere</h5>
        </Col>
      </Row>

      <span>{'Total af ' + calculateTotalMarked() + ' produkter er markeret.'}</span>

      <Row className="mt-5 option-panel">
        <Col sm={4}>
          <input type="search" placeholder="Søg" id="form1" className="form-control" value={searchFilter} onChange={(e) => setSearchFilter(e.target.value)} />
        </Col>

        <Col className="text-center button-wrapper" sm={8}>
          <Button className="button-orange" onClick={handleDeselectAll}>
            Fravælg alle
          </Button>
          <Button onClick={handleSelectAll}>{isAdding ? <Spinner animation="border" role="status" size="sm"></Spinner> : 'Tilføj alle'}</Button>
        </Col>
      </Row>
      <Row className="mt-5 mb-4">
        <Col xs={2} sm={1}>
          <label>
            <b>Billede</b>
          </label>
        </Col>
        <Col xs={2} sm={2} className="d-none d-sm-block">
          <label
            className="sorting-label"
            onClick={() => handleSort('vendor_products_sku')}
            onMouseEnter={() => setHoverField('vendor_products_sku')}
            onMouseLeave={() => setHoverField(null)}
          >
            <b>Varenummer</b>
            {sortField === 'vendor_products_sku' && (sortOrder === 'asc' ? <label>▲</label> : <label className="arrow-down">▲</label>)}
            {hoverField === 'vendor_products_sku' && sortField !== 'vendor_products_sku' && <span>▲</span>}
          </label>
        </Col>
        <Col xs={4} sm={4}>
          <label
            className="sorting-label"
            onClick={() => handleSort('vendor_products_name')}
            onMouseEnter={() => setHoverField('vendor_products_name')}
            onMouseLeave={() => setHoverField(null)}
          >
            <b>Produktnavn</b>
            {sortField === 'vendor_products_name' && (sortOrder === 'asc' ? <label>▲</label> : <label className="arrow-down">▲</label>)}
            {hoverField === 'vendor_products_name' && sortField !== 'vendor_products_name' && <span>▲</span>}
          </label>
        </Col>
        <Col xs={2} sm={2} className="d-none d-sm-block">
          <label
            className="sorting-label"
            onClick={() => handleSort('vendor_products_manufactor')}
            onMouseEnter={() => setHoverField('vendor_products_manufactor')}
            onMouseLeave={() => setHoverField(null)}
          >
            <b>Brand</b>
            {sortField === 'vendor_products_manufactor' && (sortOrder === 'asc' ? <label>▲</label> : <label className="arrow-down">▲</label>)}
            {hoverField === 'vendor_products_manufactor' && sortField !== 'vendor_products_manufactor' && <span>▲</span>}
          </label>
        </Col>
        <Col xs={2} sm={1} className="d-none d-sm-block">
          <label
            className="sorting-label"
            onClick={() => handleSort('vendor_products_price')}
            onMouseEnter={() => setHoverField('vendor_products_price')}
            onMouseLeave={() => setHoverField(null)}
          >
            <b>Pris</b>
            {sortField === 'vendor_products_price' && (sortOrder === 'asc' ? <label>▲</label> : <label className="arrow-down">▲</label>)}
            {hoverField === 'vendor_products_price' && sortField !== 'vendor_products_price' && <span>▲</span>}
          </label>
        </Col>
      </Row>

      <Row>
        <Col sm={12} className="product-container">
          {products.slice(0, visibleCount).map((product) => {
            if (product.product_variations && product.product_variations.length !== 0) {
              return <VariableProducts key={product.vendor_products_id} product={product} selectedProducts={selectedProducts} />;
            } else {
              return <SimpleProducts key={product.vendor_products_id} product={product} selectedProducts={selectedProducts} />;
            }
          })}
        </Col>
      </Row>
      <Row className="mb-1 mt-4">
        <Col>
          {visibleCount < products.length && (
            <>
              <Button onClick={handleShowMore} variant="primary">
                Vis mere
              </Button>
              <Button className="mx-2" onClick={handleShowAll} variant="primary">
                Vis alle ({totalResults})
              </Button>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};
