import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Modal, Form } from 'react-bootstrap';
import { dexieDB } from '../../../pages/import-products/shared/db';

import { VariationModalProps } from './types';
import { ProductInterface, ChildVariation, ExtraAttributes } from '../../../pages/import-products/shared/types';

const initialData: ChildVariation = {
  vendor_product_variations_id: 0,
  vendor_product_variations_parent_id: 0,
  vendor_product_variations_sku: '',
  vendor_product_variations_ean: '',
  vendor_product_variations_name: '',
  vendor_product_variations_attributes: '',
  vendor_product_variations_attributes_value: '',
  vendor_product_variations_properties: '',
  vendor_product_variations_weight: 0,
  vendor_product_variations_unit: '',
  vendor_product_variations_stock: 0,
  vendor_product_variations_price: 0,
  vendor_product_variations_stock_status: 'outofstock',
  vendor_product_variations_sales_price: 0,
  vendor_product_variations_cost_price: 0,
};

const VariationProductModal: React.FC<React.PropsWithChildren<VariationModalProps>> = (props) => {
  const [variation, setVariation] = useState<ChildVariation>(initialData);
  const [variationStock, setVariationStock] = useState<string>(
    variation.vendor_product_variations_stock ? variation.vendor_product_variations_stock.toString() : '',
  );
  const [attributes, setAttributes] = useState<ExtraAttributes[]>([]);

  const updateAttributes = async () => {
    const attributeData: ExtraAttributes[] = [];

    if (variation.vendor_product_variations_properties && typeof variation.vendor_product_variations_properties === 'string') {
      const properties = JSON.parse(variation.vendor_product_variations_properties);

      for await (const property of properties) {
        for await (const [key, value] of Object.entries(property)) {
          attributeData.push({
            name: key,
            value: value as string,
          });
        }
      }
    }

    setAttributes(attributeData);
  };

  useEffect(() => {
    updateAttributes();
  }, [variation, props.isVariationModalOpen]);

  useEffect(() => {
    if (!variation) {
      return;
    }

    let variationStockCount = 0;

    if (variationStock) {
      variationStockCount = Number(variationStock);
    }

    setVariation({
      ...variation,
      ['vendor_product_variations_stock']: variationStockCount,
    });
  }, [variationStock]);

  useEffect(() => {
    if (!props.productUnderEdit || !props.productUnderEdit.product_variations || props.variationId === 0) {
      return;
    }

    const getCurrentlyVariation = props.productUnderEdit.product_variations.filter(
      (productVariation) => productVariation.vendor_product_variations_id === props.variationId,
    );

    if (getCurrentlyVariation[0]) {
      setVariation(getCurrentlyVariation[0]);
      setVariationStock(`${Number(getCurrentlyVariation[0].vendor_product_variations_stock)}`);
    }
  }, [props.productUnderEdit, props.variationId]);

  const getVariationSalesPrice = (): number => {
    if (!props.productUnderEdit || !variation.vendor_product_variations_sales_price) {
      return 0;
    }

    return variation.vendor_product_variations_sales_price;
  };

  const getVariationCostPrice = (): number => {
    if (!props.productUnderEdit || !variation.vendor_product_variations_cost_price) {
      return 0;
    }

    return variation.vendor_product_variations_cost_price;
  };

  const handleVariationSave = async (product: ProductInterface | null | undefined): Promise<void> => {
    if (!product || !product.vendor_products_id) {
      return;
    }

    if (product.product_variations && props.variationId !== 0) {
      for (const variationIndex in product.product_variations) {
        if (product.product_variations[variationIndex].vendor_product_variations_id !== variation.vendor_product_variations_id) {
          continue;
        }

        const product_variations_properties = attributes.map((attribute) => {
          return { [attribute.name]: attribute.value };
        });

        product.product_variations[variationIndex] = { ...variation, vendor_product_variations_properties: JSON.stringify(product_variations_properties) };
      }
    }

    await dexieDB.editedProducts.update(product.vendor_products_id, product);

    props.setIsVariationModalOpen(false);
    props.setVariationId(0);
  };

  const handleVariationValueChanged = (event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>): void => {
    setVariation({
      ...variation,
      [event.target.id]: event.target.value,
    });
  };

  const handleClose = (): void => {
    props.setIsVariationModalOpen(false);
    props.setVariationId(0);
  };

  const handleAddAttribute = () => {
    const newAttribute = { name: '', value: '' };
    setAttributes((currentAttributes) => [...currentAttributes, newAttribute]);
  };

  const handleRemoveAttribute = (index: number) => {
    setAttributes((currentAttributes) => currentAttributes.filter((_, currentIndex) => currentIndex !== index));
  };

  const handleAttributeChange = (index: number, key: keyof ExtraAttributes, value: string) => {
    setAttributes((currentAttributes) => currentAttributes.map((attr, i) => (i === index ? { ...attr, [key]: value } : attr)));
  };

  const getStockStatus = (): 'instock' | 'outofstock' => {
    if (variation.vendor_product_variations_stock === 0) {
      return 'outofstock';
    } else {
      return 'instock';
    }
  };

  return (
    <Modal show={props.isVariationModalOpen} className="edit-product-modal" backdrop="static" keyboard={false}>
      <Modal.Header className="justify-content-between">
        <Modal.Title>
          <h1>Rediger variation</h1>
          <h5>Indsæt dine egne værdier i felterne nedenfor</h5>
        </Modal.Title>
        <Button className="button-orange me-2" onClick={() => handleClose()}>
          Luk
        </Button>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <Container>
            <Row>
              <Col xs={12} lg={6}>
                {variation.vendor_product_variations_image && variation.vendor_product_variations_image.length !== 0 ? (
                  <img className="modal-product-image" src={variation.vendor_product_variations_image} alt="alt" />
                ) : null}
              </Col>
              <Col xs={12} lg={6}>
                <Row>
                  <Col sm={6}>
                    <Row className="input-field">
                      <Form.Group controlId={'vendor_product_variations_sku'}>
                        <Form.Label>Varenummer / SKU</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Varenummer"
                          value={variation.vendor_product_variations_sku}
                          onChange={handleVariationValueChanged}
                        />
                      </Form.Group>
                    </Row>
                  </Col>
                  <Col sm={6}>
                    <Row className="input-field">
                      <Form.Group controlId={'vendor_product_variations_ean'}>
                        <Form.Label>Stregkode / EAN</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Stregkode / EAN"
                          value={variation.vendor_product_variations_ean}
                          onChange={handleVariationValueChanged}
                        />
                      </Form.Group>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    <Row className="input-field">
                      <Form.Group controlId={'vendor_product_variations_price'}>
                        <Form.Label>Vejl. pris </Form.Label>
                        <Form.Control
                          type="number"
                          min={0}
                          step={50}
                          placeholder="Indtast vejledende pris"
                          value={variation.vendor_product_variations_price}
                          onChange={handleVariationValueChanged}
                        />
                      </Form.Group>
                    </Row>
                  </Col>
                  <Col sm={4}>
                    <Row className="input-field">
                      <Form.Group controlId={'vendor_product_variations_sales_price'}>
                        <Form.Label>Tilbudspris</Form.Label>
                        <Form.Control
                          type="number"
                          min={0}
                          step={50}
                          placeholder="Indtast tilbudspris"
                          value={getVariationSalesPrice()}
                          onChange={handleVariationValueChanged}
                        />
                      </Form.Group>
                    </Row>
                  </Col>
                  <Col sm={4}>
                    <Row className="input-field">
                      <Form.Group controlId={'vendor_product_variations_cost_price'}>
                        <Form.Label>Kostpris</Form.Label>
                        <Form.Control
                          type="number"
                          min={0}
                          step={50}
                          placeholder="Indtast kostpris"
                          value={getVariationCostPrice()}
                          onChange={handleVariationValueChanged}
                        />
                      </Form.Group>
                    </Row>
                  </Col>
                  <Form.Text className="text-muted">* Priser opgivet i € er ganget med kurs 7,5</Form.Text>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Row className="input-field">
                      <Form.Group controlId={'vendor_product_variations_stock'}>
                        <Form.Label>Lager</Form.Label>
                        <Form.Control
                          type="number"
                          min={0}
                          placeholder="Indtast lagerbeholdning"
                          value={variationStock}
                          onChange={(event) => setVariationStock(event.target.value)}
                        />
                      </Form.Group>
                    </Row>
                  </Col>
                  <Col sm={6}>
                    <Row className="input-field">
                      <Form.Group controlId={'vendor_product_variations_stock_status'}>
                        <Form.Label>Lager status</Form.Label>
                        <Form.Control
                          type="text"
                          value={getStockStatus() === 'instock' ? 'På lager' : 'Ikke på lager'}
                          disabled
                          style={{ backgroundColor: getStockStatus() === 'instock' ? 'rgb(119, 227, 119)' : 'rgb(255 138 138)' }}
                        />
                      </Form.Group>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Row className="input-field">
                      <Form.Group controlId={'vendor_product_variations_weight'}>
                        <Form.Label>Vægt</Form.Label>
                        <Form.Control
                          type="number"
                          min={0}
                          step={5}
                          placeholder="Indtast vægt"
                          value={variation.vendor_product_variations_weight}
                          onChange={handleVariationValueChanged}
                        />
                      </Form.Group>
                    </Row>
                  </Col>

                  <Col sm={6}>
                    <Row className="input-field">
                      <Form.Group controlId={'vendor_product_variations_attributes_value'}>
                        <Form.Label>{variation.vendor_product_variations_attributes}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Indtast værdi"
                          value={variation.vendor_product_variations_attributes_value}
                          onChange={handleVariationValueChanged}
                        />
                      </Form.Group>
                    </Row>
                  </Col>
                </Row>
                {/* Attributes */}
                <Form.Label>Opret Attributter</Form.Label>
                {attributes.map((attribute, index) => (
                  <Row key={index} className="mb-2">
                    <Col sm={5}>
                      <Form.Group controlId={`attribute-name-${index}`}>
                        <Form.Control
                          type="text"
                          placeholder="Indtast navn"
                          value={attribute.name}
                          onChange={(e) => handleAttributeChange(index, 'name', e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={5}>
                      <Form.Group controlId={`attribute-value-${index}`}>
                        <Form.Control
                          type="text"
                          placeholder="Indtast værdi"
                          value={attribute.value}
                          onChange={(e) => handleAttributeChange(index, 'value', e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={2} className="d-flex">
                      <Button variant="outline-danger" onClick={() => handleRemoveAttribute(index)}>
                        Fjern
                      </Button>
                    </Col>
                  </Row>
                ))}

                <Row>
                  <Col xs={12} className="d-flex justify-content-left">
                    <Button variant="outline-dark" onClick={handleAddAttribute}>
                      Tilføj attribut
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container fluid>
            <Row>
              <Col xs={6} className="d-flex justify-content-end">
                <Button className="button-orange" onClick={() => handleClose()}>
                  Afbryd
                </Button>
              </Col>
              <Col xs={6}>
                <Button onClick={() => handleVariationSave(props.productUnderEdit)}>Gem</Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default VariationProductModal;
