import React, { ChangeEvent, useState } from 'react';
import Select, { OnChangeValue, components, OptionProps } from 'react-select';
import { ProductInterface, CategoryInterface } from 'pages/import-products/shared/types';
import { ProductEditProps } from './types';
import { useSelectedProducts } from 'contexts/selected-products';
import { dexieDB } from 'pages/import-products/shared/db';

const EditSimpleProducts: React.FC<React.PropsWithChildren<ProductEditProps>> = (props) => {
  const [isEditing, setIsEditing] = useState<keyof ProductInterface | undefined>(undefined);
  const [product, setProduct] = useState<ProductInterface | undefined | null>(props.product);
  const [inputWidth, setInputWidth] = useState<string>('');

  const onProductSelect = (tmpProduct: ProductInterface): void => {
    props.setProductUnderEdit(tmpProduct);
    props.setIsSimpleModalOpen(true);
  };

  const numberFormatter = Intl.NumberFormat('da-DK');
  const { selectedProducts, setSelectedProducts } = useSelectedProducts();

  const toggleProductSelection = (product: ProductInterface) => {
    const isProductSelected = selectedProducts.some((selected) => selected.vendor_products_id === product.vendor_products_id);

    if (isProductSelected) {
      setSelectedProducts((current) => current.filter((selected) => selected.vendor_products_id !== product.vendor_products_id));
    } else {
      setSelectedProducts((current) => [...current, product]);
    }
  };

  const categoryOptions: React.FC<OptionProps<CategoryInterface, true>> = (props) => {
    return (
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    );
  };

  const handleEditing = (key: keyof ProductInterface) => {
    setIsEditing(key);
  };

  const handleBlur = () => {
    handleProductSave();
    setIsEditing(undefined);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleProductSave();
      setIsEditing(undefined);
    }
  };

  const handleProductValueChanged = (field: keyof ProductInterface, value: ProductInterface[keyof ProductInterface], event?: ChangeEvent<HTMLInputElement>) => {
    setProduct((prevData) => {
      if (!prevData) {
        return prevData;
      }

      return {
        ...prevData,
        [field]: value,
      } as ProductInterface;
    });
    if (event) {
      setInputWidth(event.target.value);
    }
  };

  const handleEditCategories = (newValue: OnChangeValue<CategoryInterface, true>): void => {
    setProduct((prevData) => {
      if (!prevData) {
        return prevData;
      }
      return {
        ...prevData,
        vendor_products_category: newValue as CategoryInterface[],
      };
    });
  };

  const handleProductSave = async (): Promise<void> => {
    if (!product) {
      return;
    }

    await dexieDB.editedProducts.update(product.vendor_products_id, product);
  };

  const handleProductClick = (product: ProductInterface) => (event: React.MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();
    toggleProductSelection(product);
  };

  const getProductStatus = (): string => {
    if (props.product.vendor_products_status) {
      return props.product.vendor_products_status === 'draft' ? 'Kladde' : 'Udgiv';
    } else {
      return (props.product.vendor_products_status = 'draft');
    }
  };

  const isInStock = (): boolean => {
    if (props.product.vendor_products_stock) {
      return props.product.vendor_products_stock > 0;
    }
    return false;
  };

  return (
    <>
      <td className="product-simple-item text-center">
        <input
          type="checkbox"
          onClick={handleProductClick(props.product)}
          checked={selectedProducts.some((p) => p.vendor_products_id === props.product.vendor_products_id)}
          readOnly
        />
      </td>

      <td className="product-simple-item">
        {props.product.vendor_products_image && props.product.vendor_products_image.length !== 0 ? (
          <img height={50} src={props.product.vendor_products_image} alt="alt" />
        ) : null}
      </td>

      <td className="product-simple-item product-simple-item-editable d-none d-sm-table-cell">
        <div className="multi-line-wrapper">
          {isEditing === 'vendor_products_sku' ? (
            <input
              id="vendor_products_sku"
              type="text"
              value={product?.vendor_products_sku}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              onChange={(e) => handleProductValueChanged(e.target.id as keyof ProductInterface, e.target.value, e)}
              onFocus={(e) => setInputWidth(e.target.value)}
              style={{ width: `${inputWidth.length + 2 || 1}ch` }}
              autoFocus
            ></input>
          ) : (
            <>
              <div>
                <img className="editable-icon" src="/fa/svgs/solid/pen.svg" />
                <label
                  className="product-simple-item-editable-label"
                  onClick={() => {
                    handleEditing('vendor_products_sku');
                  }}
                >
                  {props.product.vendor_products_sku}
                </label>
              </div>
            </>
          )}
          <small>{props.product.vendor_products_ean}</small>
        </div>
      </td>

      <td className="product-simple-item product-simple-item-editable">
        {isEditing === 'vendor_products_name' ? (
          <input
            id="vendor_products_name"
            type="text"
            value={product?.vendor_products_name}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            onChange={(e) => handleProductValueChanged(e.target.id as keyof ProductInterface, e.target.value, e)}
            onFocus={(e) => setInputWidth(e.target.value)}
            style={{ width: `${inputWidth.length + 2 || 1}ch` }}
            autoFocus
          ></input>
        ) : (
          <>
            <img className="editable-icon" src="/fa/svgs/solid/pen.svg" />
            <label
              className="product-simple-item-editable-label"
              onClick={() => {
                handleEditing('vendor_products_name');
              }}
            >
              {props.product.vendor_products_name}
            </label>
          </>
        )}
      </td>

      <td className="product-simple-item product-simple-item-editable d-none d-sm-table-cell">
        {isEditing === 'vendor_products_category' ? (
          <Select
            id="vendor_products_category"
            options={props.webshopCategories}
            placeholder="Vælg kategori"
            isSearchable={true}
            isMulti={true}
            getOptionValue={(option: CategoryInterface) => option.id}
            getOptionLabel={(option: CategoryInterface) => `${option.name}`}
            components={{ Option: categoryOptions, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            onChange={handleEditCategories}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            noOptionsMessage={() => `${'Ingen kategorier'}`}
            defaultValue={props.product.vendor_products_category as CategoryInterface[]}
            menuIsOpen
          />
        ) : (
          <>
            <img className="editable-icon" src="/fa/svgs/solid/pen.svg" />
            <label
              className="product-simple-item-editable-label"
              onClick={() => {
                handleEditing('vendor_products_category');
              }}
            >
              {props.product.vendor_products_category?.length ? (
                <>
                  {props.product.vendor_products_category instanceof Array
                    ? props.product.vendor_products_category.map((category: string | CategoryInterface, index, array) => {
                        const separator = index < array.length - 1 ? ', ' : '';
                        return typeof category === 'object' ? category.name + separator : category + separator;
                      })
                    : 'Tilføj kategori'}
                </>
              ) : (
                <p className="mb-0">Tilføj kategori</p>
              )}
            </label>
          </>
        )}
      </td>

      <td className="product-simple-item product-simple-item-editable d-sm-table-cell">
        <div className="d-flex align-items-center">
          <img className="editable-icon" src="/fa/svgs/solid/pen.svg" />
          <div className="multi-line-wrapper">
            {props.product.vendor_products_sales_price ? (
              <>
                {isEditing === 'vendor_products_price' ? (
                  <input
                    id="vendor_products_price"
                    type="number"
                    value={product?.vendor_products_price}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => handleProductValueChanged(e.target.id as keyof ProductInterface, e.target.value, e)}
                    onFocus={(e) => setInputWidth(e.target.value)}
                    style={{ width: `${inputWidth.length + 2 || 1}ch` }}
                    autoFocus
                  />
                ) : (
                  <>
                    <label
                      className="product-simple-item-editable-label"
                      onClick={() => {
                        handleEditing('vendor_products_price');
                      }}
                    >
                      <s>{numberFormatter.format(props.product.vendor_products_price)} DKK</s>
                    </label>
                  </>
                )}

                {isEditing === 'vendor_products_sales_price' ? (
                  <input
                    id="vendor_products_sales_price"
                    type="number"
                    value={product?.vendor_products_sales_price}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => handleProductValueChanged(e.target.id as keyof ProductInterface, e.target.value, e)}
                    onFocus={(e) => setInputWidth(e.target.value)}
                    style={{ width: `${inputWidth.length + 2 || 1}ch` }}
                    autoFocus
                  />
                ) : (
                  <>
                    <label
                      className="product-simple-item-editable-label"
                      onClick={() => {
                        handleEditing('vendor_products_sales_price');
                      }}
                    >
                      {numberFormatter.format(props.product.vendor_products_sales_price)} DKK
                    </label>
                  </>
                )}
              </>
            ) : (
              <>
                {isEditing === 'vendor_products_price' || isEditing === 'vendor_products_sales_price' ? (
                  <>
                    {isEditing === 'vendor_products_price' ? (
                      <input
                        id={'vendor_products_price'}
                        type="number"
                        value={product?.vendor_products_price}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => handleProductValueChanged(e.target.id as keyof ProductInterface, e.target.value, e)}
                        onFocus={(e) => setInputWidth(e.target.value)}
                        style={{ width: `${inputWidth.length + 2 || 1}ch` }}
                        autoFocus
                      />
                    ) : (
                      <>
                        <label
                          className="product-simple-item-editable-label"
                          onClick={() => {
                            handleEditing('vendor_products_price');
                          }}
                        >
                          <s>{numberFormatter.format(props.product.vendor_products_price)} DKK</s>
                        </label>
                        <input
                          id={'vendor_products_sales_price'}
                          type="number"
                          value={product?.vendor_products_sales_price}
                          onBlur={handleBlur}
                          onKeyDown={handleKeyDown}
                          onChange={(e) => handleProductValueChanged(e.target.id as keyof ProductInterface, e.target.value, e)}
                          onFocus={(e) => setInputWidth(e.target.value)}
                          style={{ width: `${inputWidth.length + 2 || 1}ch` }}
                          autoFocus
                        />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <label
                      className="product-simple-item-editable-label"
                      onClick={() => {
                        handleEditing('vendor_products_price');
                      }}
                    >
                      {numberFormatter.format(props.product.vendor_products_price)} DKK
                    </label>
                    <label
                      className="product-simple-item-editable-label add-sales"
                      onClick={() => {
                        handleEditing('vendor_products_sales_price');
                      }}
                    >
                      + Tilføj udsalgspris
                    </label>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </td>

      <td className="product-simple-item product-simple-item-editable d-none d-sm-table-cell">
        {isEditing === 'vendor_products_status' ? (
          <select
            id="vendor_products_status"
            aria-label="status"
            onBlur={handleBlur}
            value={product?.vendor_products_status}
            onChange={(e) => handleProductValueChanged(e.target.id as keyof ProductInterface, e.target.value)}
            onFocus={(e) => setInputWidth(e.target.value)}
            style={{ width: `${inputWidth.length + 2 || 1}ch` }}
            autoFocus
          >
            <option value="draft">Kladde</option>
            <option value="publish">Udgiv</option>
          </select>
        ) : (
          <>
            <img className="editable-icon" src="/fa/svgs/solid/pen.svg" />
            <label
              className="product-simple-item-editable-label"
              onClick={() => {
                handleEditing('vendor_products_status');
              }}
            >
              {getProductStatus()}
            </label>
          </>
        )}
      </td>

      <td className="product-simple-item product-simple-item-editable d-none d-sm-none d-md-none d-lg-none d-xl-table-cell">
        {isEditing === 'vendor_products_stock' ? (
          <input
            id="vendor_products_stock"
            type="number"
            value={product?.vendor_products_stock}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            onChange={(e) => handleProductValueChanged(e.target.id as keyof ProductInterface, e.target.value, e)}
            onFocus={(e) => setInputWidth(e.target.value)}
            style={{ width: `${inputWidth.length + 2 || 1}ch` }}
            autoFocus
          ></input>
        ) : (
          <>
            <img className="editable-icon" src="/fa/svgs/solid/pen.svg" />
            <label
              className="product-simple-item-editable-label"
              onClick={() => {
                handleEditing('vendor_products_stock');
              }}
            >
              <span className={isInStock() ? 'instock' : 'out-of-stock'}></span>
              {props.product.vendor_products_stock} stk.
            </label>
          </>
        )}
      </td>

      <td className="product-simple-item text-center">
        <span className="product-simple-btn" onClick={() => onProductSelect(props.product)}>
          <img src="/fa/svgs/solid/ellipsis-v.svg" />
        </span>
      </td>
    </>
  );
};

export default EditSimpleProducts;
