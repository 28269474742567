import React, { ChangeEvent, useEffect, useState } from 'react';
import { ProductInterface, CategoryInterface, ChildVariation } from 'pages/import-products/shared/types';
import { VariableEditProps } from './types';
import { useSelectedProducts } from 'contexts/selected-products';
import Select, { components, OnChangeValue, OptionProps } from 'react-select';
import { dexieDB } from 'pages/import-products/shared/db';

const EditVariableProducts: React.FC<React.PropsWithChildren<VariableEditProps>> = (props) => {
  const numberFormatter = Intl.NumberFormat('da-DK');
  const { selectedProducts, setSelectedProducts } = useSelectedProducts();
  const [selectedVariations, setSelectedVariations] = useState<number[]>([]);
  const [isEditing, setIsEditing] = useState<{ key: keyof ProductInterface | keyof ChildVariation; id: number | undefined } | undefined>(undefined);
  const [product, setProduct] = useState<ProductInterface | undefined | null>(props.product);
  const [variationProducts, setVariationProducts] = useState<ChildVariation[] | undefined | null>(props.product.product_variations);
  const [inputWidth, setInputWidth] = useState<string>('');

  useEffect(() => {
    if (!props.product.product_variations) {
      return;
    }
    if (selectedProducts.some((product) => product.vendor_products_id === props.product.vendor_products_id)) {
      const variationIds = props.product.product_variations
        .map((variation) => variation.vendor_product_variations_id)
        .filter((id): id is number => id !== undefined);

      setSelectedVariations(variationIds);
    } else {
      setSelectedVariations([]);
    }
  }, [selectedProducts, props.product]);

  const handleProductClick = (product: ProductInterface) => (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    const isProductSelected = selectedProducts.some((selected) => selected.vendor_products_id === product.vendor_products_id);

    setSelectedProducts((currentProducts) =>
      isProductSelected ? currentProducts.filter((selected) => selected.vendor_products_id !== product.vendor_products_id) : [...currentProducts, product],
    );
  };

  const handleVariationClick = (variation: ChildVariation) => (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    const variationId = variation.vendor_product_variations_id;
    if (variationId === undefined) return;

    setSelectedVariations((currentVariations) =>
      currentVariations.includes(variationId) ? currentVariations.filter((id) => id !== variationId) : [...currentVariations, variationId],
    );
  };

  const onProductSelect = (tmpProduct: ProductInterface): void => {
    props.setProductUnderEdit(tmpProduct);
    props.setIsSimpleModalOpen(true);
  };

  const onVariableProductSelect = (tmpProduct: ProductInterface, variantId: number | undefined): void => {
    if (!variantId) {
      return;
    }

    props.setProductUnderEdit(tmpProduct);
    props.setVariationId(variantId);
    props.setIsVariableModalOpen(true);
  };

  const categoryOptions: React.FC<OptionProps<CategoryInterface, true>> = (props) => {
    return (
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null} // react-select handles selection, so no action needed here
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    );
  };

  const handleEditing = (key: keyof ProductInterface | keyof ChildVariation, id?: number) => {
    if (id) {
      setIsEditing({ key, id });
    } else {
      setIsEditing({ key, id: undefined });
    }
  };

  const handleBlur = () => {
    handleProductSave();
    setIsEditing(undefined);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleProductSave();
      setIsEditing(undefined);
    }
  };

  const handleProductValueChanged = (field: keyof ProductInterface, value: ProductInterface[keyof ProductInterface]) => {
    setProduct((prevData) => {
      if (!prevData) {
        return prevData;
      }

      return {
        ...prevData,
        [field]: value,
      } as ProductInterface;
    });
  };

  const handleVariationValueChanged = (
    field: keyof ChildVariation,
    value: ChildVariation[keyof ChildVariation],
    selectedVariation: ChildVariation,
    event?: ChangeEvent<HTMLInputElement>,
  ) => {
    if (event) {
      setInputWidth(event.target.value);
    }
    setVariationProducts((prevData) => {
      if (!Array.isArray(prevData)) {
        return prevData;
      }

      const updatedVariations = prevData.map((variation) => {
        if (variation.vendor_product_variations_id === selectedVariation.vendor_product_variations_id) {
          return {
            ...variation,
            [field]: value,
          };
        }

        return variation;
      });

      handleProductValueChanged('product_variations', updatedVariations);
      return updatedVariations;
    });
  };

  const handleEditCategories = (newValue: OnChangeValue<CategoryInterface, true>): void => {
    setProduct((prevData) => {
      if (!prevData) {
        return prevData;
      }
      return {
        ...prevData,
        vendor_products_category: newValue as CategoryInterface[],
      };
    });
  };

  const handleProductSave = async (): Promise<void> => {
    if (!product) {
      return;
    }

    await dexieDB.editedProducts.update(product.vendor_products_id, product);
  };

  const getProductStatus = (): string => {
    if (props.product.vendor_products_status) {
      return props.product.vendor_products_status === 'draft' ? 'Kladde' : 'Udgiv';
    } else {
      return (props.product.vendor_products_status = 'draft');
    }
  };

  return (
    <>
      <tr>
        <td className="product-simple-item text-center">
          <input
            type="checkbox"
            checked={selectedProducts.some((p) => p.vendor_products_id === props.product.vendor_products_id)}
            onClick={handleProductClick(props.product)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => event.stopPropagation()}
            readOnly={true}
          />
        </td>

        <td className="product-simple-item">
          <img height={50} src={props.product.vendor_products_image} alt="alt" />
        </td>

        <td className="product-simple-item d-none d-sm-table-cell">
          <div className="multi-line-wrapper">
            <label>{props.product.product_variations?.[0].vendor_product_variations_sku}</label>
            <small>{props.product.product_variations?.[0].vendor_product_variations_ean}</small>
          </div>
        </td>

        <td className="product-simple-item">
          <label>{props.product.vendor_products_name}</label>
        </td>

        <td className="product-simple-item product-simple-item-editable d-none d-sm-table-cell">
          {isEditing && isEditing.key === 'vendor_products_category' ? (
            <Select
              id="vendor_products_category"
              options={props.webshopCategories}
              placeholder="Vælg kategori"
              isSearchable={true}
              isMulti={true}
              getOptionValue={(option: CategoryInterface) => option.id}
              getOptionLabel={(option: CategoryInterface) => `${option.name}`}
              components={{ Option: categoryOptions, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              onChange={handleEditCategories}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              noOptionsMessage={() => `${'Ingen kategorier'}`}
              defaultValue={props.product.vendor_products_category as CategoryInterface[]}
              menuIsOpen
            />
          ) : (
            <>
              <img className="editable-icon" src="/fa/svgs/solid/pen.svg" />
              <label
                className="product-simple-item-editable-label"
                onClick={() => {
                  handleEditing('vendor_products_category');
                }}
              >
                {props.product.vendor_products_category?.length ? (
                  <>
                    {props.product.vendor_products_category instanceof Array
                      ? props.product.vendor_products_category.map((category: string | CategoryInterface, index, array) => {
                          const separator = index < array.length - 1 ? ', ' : '';
                          return typeof category === 'object' ? category.name + separator : category + separator;
                        })
                      : 'Tilføj kategori'}
                  </>
                ) : (
                  <p className="mb-0">Tilføj kategori</p>
                )}
              </label>
            </>
          )}
        </td>

        <td className="product-simple-item"></td>

        <td className="product-simple-item product-simple-item-editable d-none d-sm-table-cell">
          {isEditing && isEditing.key === 'vendor_products_status' ? (
            <select
              id="vendor_products_status"
              aria-label="status"
              onBlur={handleBlur}
              value={product?.vendor_products_status}
              onChange={(e) => handleProductValueChanged(e.target.id as keyof ProductInterface, e.target.value)}
            >
              <option value="draft">Kladde</option>
              <option value="publish">Udgiv</option>
            </select>
          ) : (
            <>
              <img className="editable-icon" src="/fa/svgs/solid/pen.svg" />
              <label
                className="product-simple-item-editable-label"
                onClick={() => {
                  handleEditing('vendor_products_status');
                }}
              >
                {getProductStatus()}
              </label>
            </>
          )}
        </td>

        <td className="product-simple-item d-none d-sm-none d-md-none d-lg-none d-xl-table-cell"></td>

        <td className="product-simple-item text-center">
          <span className="product-simple-btn" onClick={() => onProductSelect(props.product)}>
            <img src="/fa/svgs/solid/ellipsis-v.svg" />
          </span>
        </td>
      </tr>
      {props.product.product_variations &&
        props.product.product_variations.map((variation, index) => (
          <tr key={index} className={selectedProducts.some((product) => product.vendor_products_id === props.product.vendor_products_id) ? 'selected' : ''}>
            <td colSpan={2} className="product-variable-item">
              <input
                type="checkbox"
                className="me-2 ms-5"
                checked={variation.vendor_product_variations_id !== undefined && selectedVariations.includes(variation.vendor_product_variations_id)}
                onClick={() => handleVariationClick(variation)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => event.stopPropagation()}
                disabled={!selectedProducts.some((product) => product.vendor_products_id === props.product.vendor_products_id)}
                readOnly={true}
              />
              {variation.vendor_product_variations_image && variation.vendor_product_variations_image.length !== 0 ? (
                <img height={50} src={variation.vendor_product_variations_image} alt="alt" />
              ) : null}
            </td>

            <td className="product-variable-item d-none d-sm-table-cell">
              <div className="multi-line-wrapper">
                <label>{variation.vendor_product_variations_sku}</label>
                <small>{variation.vendor_product_variations_ean}</small>
              </div>
            </td>

            <td className="product-variable-item">
              <label>{variation.vendor_product_variations_name}</label>
            </td>
            <td className="product-variable-item d-none d-sm-table-cell"></td>

            <td className="product-variable-item product-variable-item-editable d-sm-table-cell">
              <div className="d-flex align-items-center">
                <img className="editable-icon" src="/fa/svgs/solid/pen.svg" />
                <div className="multi-line-wrapper">
                  {variation.vendor_product_variations_sales_price ? (
                    <>
                      {isEditing && isEditing.key === 'vendor_product_variations_price' && isEditing.id === variation.vendor_product_variations_id ? (
                        <input
                          id="vendor_product_variations_price"
                          type="number"
                          value={variationProducts?.[index].vendor_product_variations_price}
                          onBlur={handleBlur}
                          onKeyDown={handleKeyDown}
                          onChange={(e) => handleVariationValueChanged(e.target.id as keyof ChildVariation, e.target.value, variation, e)}
                          onFocus={(e) => setInputWidth(e.target.value)}
                          style={{ width: `${inputWidth.length + 2 || 1}ch` }}
                          autoFocus
                        />
                      ) : (
                        <label
                          className="product-variable-item-editable-label"
                          onClick={() => {
                            handleEditing('vendor_product_variations_price', variation.vendor_product_variations_id);
                          }}
                        >
                          <s>{numberFormatter.format(variation.vendor_product_variations_price)} DKK</s>
                        </label>
                      )}

                      {isEditing && isEditing.key === 'vendor_product_variations_sales_price' && isEditing.id === variation.vendor_product_variations_id ? (
                        <input
                          id="vendor_product_variations_sales_price"
                          type="number"
                          value={variationProducts?.[index].vendor_product_variations_sales_price}
                          onBlur={handleBlur}
                          onKeyDown={handleKeyDown}
                          onChange={(e) => handleVariationValueChanged(e.target.id as keyof ChildVariation, e.target.value, variation, e)}
                          onFocus={(e) => setInputWidth(e.target.value)}
                          style={{ width: `${inputWidth.length + 2 || 1}ch` }}
                          autoFocus
                        />
                      ) : (
                        <label
                          className="product-variable-item-editable-label"
                          onClick={() => {
                            handleEditing('vendor_product_variations_sales_price', variation.vendor_product_variations_id);
                          }}
                        >
                          {numberFormatter.format(variation.vendor_product_variations_sales_price)} DKK
                        </label>
                      )}
                    </>
                  ) : (
                    <>
                      {(isEditing && isEditing.key === 'vendor_product_variations_price' && isEditing.id === variation.vendor_product_variations_id) ||
                      (isEditing && isEditing.key === 'vendor_product_variations_sales_price' && isEditing.id === variation.vendor_product_variations_id) ? (
                        <>
                          {isEditing && isEditing.key === 'vendor_product_variations_price' && isEditing.id === variation.vendor_product_variations_id ? (
                            <input
                              id={'vendor_product_variations_price'}
                              type="number"
                              value={variationProducts?.[index].vendor_product_variations_price}
                              onBlur={handleBlur}
                              onKeyDown={handleKeyDown}
                              onChange={(e) => handleVariationValueChanged(e.target.id as keyof ChildVariation, e.target.value, variation, e)}
                              onFocus={(e) => setInputWidth(e.target.value)}
                              style={{ width: `${inputWidth.length + 2 || 1}ch` }}
                              autoFocus
                            />
                          ) : (
                            <>
                              <label
                                className="product-variable-item-editable-label"
                                onClick={() => {
                                  handleEditing('vendor_product_variations_price', variation.vendor_product_variations_id);
                                }}
                              >
                                <s>{numberFormatter.format(variation.vendor_product_variations_price)} DKK</s>
                              </label>
                              <input
                                id={'vendor_product_variations_sales_price'}
                                type="number"
                                value={variationProducts?.[index].vendor_product_variations_sales_price}
                                onBlur={handleBlur}
                                onKeyDown={handleKeyDown}
                                onChange={(e) => handleVariationValueChanged(e.target.id as keyof ChildVariation, e.target.value, variation, e)}
                                onFocus={(e) => setInputWidth(e.target.value)}
                                style={{ width: `${inputWidth.length + 2 || 1}ch` }}
                                autoFocus
                              />
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <label
                            className="product-variable-item-editable-label"
                            onClick={() => {
                              handleEditing('vendor_product_variations_price', variation.vendor_product_variations_id);
                            }}
                          >
                            {numberFormatter.format(variation.vendor_product_variations_price)} DKK
                          </label>
                          <label
                            className="product-variable-item-editable-label add-sales"
                            onClick={() => {
                              handleEditing('vendor_product_variations_sales_price', variation.vendor_product_variations_id);
                            }}
                          >
                            + Tilføj udsalgspris
                          </label>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </td>

            <td className="product-variable-item d-none d-sm-table-cell"></td>

            <td className="product-variable-item product-variable-item-editable d-none d-sm-none d-md-none d-lg-none d-xl-table-cell">
              {isEditing && isEditing.key === 'vendor_product_variations_stock' && isEditing.id === variation.vendor_product_variations_id ? (
                <input
                  id="vendor_product_variations_stock"
                  type="number"
                  value={variationProducts?.[index].vendor_product_variations_stock}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => handleVariationValueChanged(e.target.id as keyof ChildVariation, e.target.value, variation, e)}
                  onFocus={(e) => setInputWidth(e.target.value)}
                  style={{ width: `${inputWidth.length + 2 || 1}ch` }}
                  autoFocus
                ></input>
              ) : (
                <>
                  <img className="editable-icon" src="/fa/svgs/solid/pen.svg" />
                  <label
                    className="product-variable-item-editable-label"
                    onClick={() => {
                      handleEditing('vendor_product_variations_stock', variation.vendor_product_variations_id);
                    }}
                  >
                    <span
                      className={variation.vendor_product_variations_stock && variation.vendor_product_variations_stock > 0 ? 'instock' : 'out-of-stock'}
                    ></span>
                    {variation.vendor_product_variations_stock} stk.
                  </label>
                </>
              )}
            </td>

            <td className="product-simple-item text-center">
              <span className="product-variable-btn" onClick={() => onVariableProductSelect(props.product, variation.vendor_product_variations_id)}>
                <img src="/fa/svgs/solid/ellipsis-v.svg" />
              </span>
            </td>
          </tr>
        ))}
    </>
  );
};

export default EditVariableProducts;
