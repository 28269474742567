import React from 'react';
//Styles
import { Col, Row } from 'react-bootstrap';
import ProductContent from './select-products/product-content';
import { ProductInterface } from '../../pages/import-products/shared/types';
import { ProductSelectProps } from './types';
import { dexieDB } from '../../pages/import-products/shared/db';

const SelectSimpleProducts: React.FC<React.PropsWithChildren<ProductSelectProps>> = (props) => {
  const isSelected = (productId: number): boolean => {
    if (props.selectedProducts) {
      return props.selectedProducts.some((tmpProduct) => tmpProduct.vendor_products_id === productId);
    }

    return false;
  };

  const handleProductSelect = async (product: ProductInterface): Promise<void> => {
    if (product.vendor_products_id) {
      const updatedProduct: ProductInterface = {
        ...product,
        vendor_products_status: 'draft',
      };

      if (isSelected(product.vendor_products_id) === true) {
        await dexieDB.selectedProducts.delete(product.vendor_products_id);
        await dexieDB.editedProducts.delete(product.vendor_products_id);
      } else {
        await dexieDB.selectedProducts.add(updatedProduct);
        await dexieDB.editedProducts.add(updatedProduct);
      }
    }
  };

  return (
    <Row className="product-simple" key={props.product.vendor_products_sku}>
      <Col sm={12} onClick={() => handleProductSelect(props.product)}>
        <ProductContent
          product={props.product}
          showImage={true}
          showEan={true}
          showSku={true}
          showName={true}
          showManufactor={true}
          showPrice={true}
          showButton={true}
          buttonText={props.product.vendor_products_id && isSelected(props.product.vendor_products_id) === true ? <i className="fas fa-check"></i> : 'Tilføj'}
        />
      </Col>
    </Row>
  );
};

export default SelectSimpleProducts;
